import { providerList } from '@blax-modules/base-front-end/resources/js/utilities/providers';

import { Home } from './parts/home.js';
providerList.instantiate('Home', Home);

import { Map } from './parts/map.js';
providerList.instantiate('Map', Map);

import { Menu } from './parts/menu.js';
providerList.instantiate('Menu', Menu);

import { Header } from './parts/header.js';
providerList.instantiate('Header', Header);
